// Global CSS
body {
  margin: 0;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

// Anchors
a {
    color: #069a50;
}

// buttons
button:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

// Header search bar placeholder color overrides
#searchbar::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #ffffff;
    opacity:  0.6;
}
#searchbar:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #ffffff;
   opacity:  0.6;
}
#searchbar::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #ffffff;
   opacity:  0.6;
}
#searchbar:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #ffffff;
   opacity:  0.6;
}
#searchbar::-ms-input-placeholder { /* Microsoft Edge */
   color:    #ffffff;
   opacity:  0.6;
}
#searchbar::placeholder { /* Most modern browsers support this now. */
   color:    #ffffff;
   opacity:  0.6;
}

// Header search bar button overrides
.sui-search-box__submit {
  display: none;
  // opacity: 1;
  // box-shadow: none !important;
  // border-top-left-radius: 0px !important;
  // border-bottom-left-radius: 0px !important;
  // outline: none !important;
  // color: #ffffff !important;
  // background: none;
  // background-color: #069a50;
  // padding: 6px 16px;
  // font-size: 0.875rem;
  // min-width: 64px;
  // box-sizing: border-box;
  // transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
  // font-weight: 500;
  // line-height: 1.75;
  // border-radius: 4px;
  // letter-spacing: 0.02857em;
  // text-transform: uppercase;
}
// .sui-search-box__submit:hover {
//     background: none;
//     background-color: #047b40;
// }
//
// .sui-search-box__submit:active {
//   background-color: #046b38;
// }

// Search bar autocomplete overrides
.autocomplete .sui-search-box__text-input {
  box-shadow: none;
}
.sui-search-box__autocomplete-container li[aria-selected="true"] {
    background:rgb(6, 154, 80);
    color: #ffffff;
}
.sui-search-box__autocomplete-container li:hover {
    background: rgb(6, 154, 80);
    color: #ffffff;
}
.sui-search-box__autocomplete-container:focus {
    outline: none;
}
.sui-search-box__autocomplete-container li:focus {
    outline: none;
}
.sui-search-box__autocomplete-container li em {
    font-style: normal;
    color: rgb(6, 154, 80);
    background: rgba(6, 154, 80, 0.2);
}

// Layout CSS
.sui-layout-header {
  // display: none;
  border: none;
  background-color: #f2f2f2;
  padding-top: 32px;
  padding-bottom: 0px;
}

.sui-layout {
  padding-top: 64px;
  padding-bottom: 50px;
  min-height: 100vh;
  background-color: #f2f2f2;
}

.sui-layout-body {
  background: none;
  // padding-top: 64px;
  // padding-bottom: 50px;

}
.sui-layout-body:after {
  display: none;
}

// Search UI more buttons
.sui-facet-view-more {
  color: #069a50;
}

.sui-paging .rc-pagination-item a {
    color: #069a50;
}

.sui-paging .rc-pagination-item:hover a {
    color: #046233;
    text-decoration: none;
}

.rc-pagination-item:hover {
    border-color: #08c466;
}

.sui-paging .rc-pagination-item-active a {
    color: #4f4f4f;
    font-weight: 700;
}

.sui-layout-sidebar-toggle {
  opacity: 1;
  border: none;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  outline: none !important;
  color: #ffffff !important;
  background: none;
  background-color: #069a50;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}
.sui-layout-sidebar-toggle:hover {
    background: none;
    background-color: #047b40;
}

.sui-layout-sidebar-toggle:active {
  background-color: #046b38;
}

.facet-valid {
  border: none;
  background: none;
}

@media screen and (max-width: 800px) {
  .sui-layout-header {
    padding-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .sui-layout {
    padding-top: 50px;
  }
}
